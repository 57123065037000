<template>
    <div class="page page--no-header">
        <div class="container text--sm--center">
            <div class="d--sm--flex justify-content--sm--center"><ChangePasswordSuccessIcon class="mb--sm--2"/></div>
            <div class="logo-branch mb--sm--14">Đổi mật khẩu thành công</div>
            <div class="row">
                <div class="col col--sm--12 col--md--6 col--md--offset--3"><HdButton v-on:click="$router.push('/').catch(()=>{})" fullWidth>Quay về trang chủ</HdButton></div>
            </div>
        </div>
    </div>
</template>

<script>
// Impport Components
import HdButton from '@/components/HdButton'

import ChangePasswordSuccessIcon from '@/assets/change-password-success.svg'
export default {
  components: {
    HdButton,
    ChangePasswordSuccessIcon
  },
  data () {
    return {}
  }
}
</script>
